import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import MapComponent from "../../components/MapComponent";
import "./Payment.css";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  GeoPoint,
} from "firebase/firestore";
import { GeoHash } from "geohash";
import { auth, firestore } from "../../firebase";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import { createRide } from "../../services/TripServices";
import fetchPaymentMethods from "../../services/paymentService";
import PaymentMethodItem from "../../components/PaymentMethodItem";

const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const savedLat = localStorage.getItem("currentLat");
  const savedLng = localStorage.getItem("currentLng");

  const initialLat = savedLat ? parseFloat(savedLat) : null;
  const initialLng = savedLng ? parseFloat(savedLng) : null;

  const { state } = useLocation();
  const {
    riderData,
    sourceLat,
    sourceLng,
    destinationLat,
    destinationLng,
    miles,
    perMilePrice,
    vehicle_category,
    vehicle_type,
    fare,
  } = state;
  // const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [directions, setDirections] = useState(null);
  const rider =
    riderData == undefined ? state.destinationState.riderData : riderData;

  useEffect(() => {
    setIsLoading(true);

    fetchPaymentMethods()
      .then((paymentMethodsArray) => {
        // console.log(
        //   "fetchPaymentMethods paymentMethodsArray: ",
        //   paymentMethodsArray
        // );
        setPaymentMethods(paymentMethodsArray || []);
        setIsLoading(false);

        if (paymentMethodsArray && paymentMethodsArray.length > 0) {
          setSelectedPaymentMethodIndex(0);
        }
      })
      .catch((error) => {
        console.log("fetchPaymentMethods Error: ", error);
      });
  }, []);

  const destinations = [
    {
      name: "",
      address: rider.sourceAddress,
      destinationType: "departure",
    },
    {
      name: "",
      address: rider.destinationAddress,
      destinationType: "arrival",
    },
  ];

  const onSelectPaymentMethod = (index) => {
    setSelectedPaymentMethodIndex(index);
  };

  const onAddNewMethod = () => {
    navigate("/addnewmethod", {
      state: { destinationState: { ...state }, page: "payment" },
    });
  };

  const onMapLoad = (mapProps, map) => {
    const { google } = mapProps;

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: "red",
      },
    });
    const origin = new google.maps.LatLng(sourceLat, sourceLng);
    const destinationLatLng = new google.maps.LatLng(
      destinationLat,
      destinationLng
    );

    const request = {
      origin,
      destination: destinationLatLng,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      console.log("got directions result: ", result, status);
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result);
        directionsRenderer.setDirections(result);
        directionsRenderer.setMap(map);
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  return (
    <div className="home-screen">
      <MapComponent
        lat={initialLat}
        lng={initialLng}
        onMapLoad={onMapLoad}
        directions={directions}
      />

      <div className="bookmap-container">
        <div className="bookmap-overlay">
          <div className="bookheader">
            <button className="back-button" onClick={() => navigate(-1)}>
              <BsArrowLeftShort className="headerback-button" />
            </button>
            <h1 className="booktext m-2">Payment</h1>
          </div>
          <Destinations
            state={state}
            destinations={destinations}
            paymentMethods={paymentMethods}
            selectedPaymentMethodIndex={selectedPaymentMethodIndex}
            onSelectPaymentMethod={onSelectPaymentMethod}
            onAddNewMethod={onAddNewMethod}
          />
        </div>
      </div>
    </div>
  );
};

const Destinations = ({
  state,
  destinations,
  paymentMethods,
  selectedPaymentMethodIndex,
  onSelectPaymentMethod,
  onAddNewMethod,
}) => {
  const {
    riderData,
    miles,
    perMilePrice,
    vehicle_category,
    vehicle_type,
    fare,
    distance,
    rajloXFare,
    distanceText,
    durationText,
  } = state;
  const {
    sourceLat,
    sourceLng,
    destinationLat,
    destinationLng,
    destinationAddress,
    sourceAddress,
  } = riderData;
  const amountToPay =
    vehicle_category === "Rajlo X"
      ? rajloXFare
      : fare === undefined
      ? state.destinationState.fare
      : fare;

  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleBookRide = async () => {
    setIsLoading(true);

    console.log("selectedPaymentMethodIndex", selectedPaymentMethodIndex);
    console.log("paymentMethods", paymentMethods);

    // Retrieve the selected vehicle from localStorage once:
    const selectedVehicleStr = localStorage.getItem("selectedVehicle");
    const storedVehicle = selectedVehicleStr ? JSON.parse(selectedVehicleStr) : null;

    if (!storedVehicle) {
      console.error("No selected vehicle found in localStorage.");
      setIsLoading(false);
      return;
    }

    const selectedPaymentMethod = paymentMethods[selectedPaymentMethodIndex];

    const originHash = GeoHash.encodeGeoHash(sourceLat, sourceLng);
    const destinationHash = GeoHash.encodeGeoHash(
      destinationLat,
      destinationLng
    );
    const originCoords = new GeoPoint(sourceLat, sourceLng);
    const destinationCoords = new GeoPoint(destinationLat, destinationLng);

    const rideData = {
      distanceText, durationText,
      destination: {
        coords: destinationCoords,
        geohash: destinationHash,
        address: destinationAddress,
        latitude: destinationLat,
        longitude: destinationLng,
      },
      driver: null,
      fare: amountToPay,
      miles: distance,
      // Add 'g' object to mimic geofirestore coordinates
      g: {
        geohash: originHash,
        geopoint: originCoords,
      },
      origin: {
        coords: originCoords,
        geohash: originHash,
        address: sourceAddress,
        latitude: sourceLat,
        longitude: sourceLng,
      },
      rider: JSON.parse(localStorage.getItem("userLogin")).uid,
      status: "pendingPickup",
      vehicleCategory: storedVehicle.category,
      vehicleType: storedVehicle.type,
      rider_did: generateRandomId(20),
      selectedPaymentMethod: selectedPaymentMethod,
    };

    console.log("creating ride: ", JSON.stringify(rideData));

    const ride = await createRide(rideData);
    console.log("Ride from createRide", ride);

    if (ride.success) {
      navigate("/searchingfordrivers", {
        state: {
          riderData,
          miles,
          perMilePrice,
          vehicle_category: storedVehicle.category,
          vehicle_type: storedVehicle.type,
          tripId: ride.id,
        },
      });
    } else {
      console.log("Error creating ride");
      setErrorMessage(ride.errorMessage);
      setShowErrorDialog(true);
    }

    setIsLoading(false);
  };

  const handleCloseErrorDialog = () => {
    setShowErrorDialog(false);
    setErrorMessage("");
  };

  const continueRide = () => (
    <button onClick={handleBookRide} className="buttonStyleFw">
      Book Ride
    </button>
  );

  const generateRandomId = (length) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return (
    <div className="recent-destinations-container">
      <div
        className="recent-destinations-bar"
        onClick={() => setOpen(!isOpen)}
      ></div>
      {isOpen && (
        <div style={{ marginRight: "16px", marginLeft: "16px" }}>
          <ul className="recent-destinations">
            {destinations.map((destination, index) => (
              <li key={index} className="payment-destination-item">
                <div className="payment-destination-icon">
                  {destination.destinationType === "departure" ? (
                    <BsFillRecord2Fill
                      className="paymentfillicon"
                      style={{ marginRight: "5px" }}
                    />
                  ) : destination.destinationType === "arrival" ? (
                    <FaMapMarkerAlt
                      className="tripsecondicon"
                      style={{ marginRight: "5px", color: "red" }}
                    />
                  ) : (
                    <FaMapMarkerAlt
                      className="paymentlocationicon"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                </div>
                <div className="destination-item-detail">
                  <div className="destination-name">{destination.address}</div>
                </div>
              </li>
            ))}
          </ul>
          <PaymentMethodsInfo
            rideInfo={state}
            paymentMethods={paymentMethods}
            selectedPaymentMethodIndex={selectedPaymentMethodIndex}
            onSelectPaymentMethod={onSelectPaymentMethod}
            onAddNewMethod={onAddNewMethod}
          />

          {paymentMethods && paymentMethods.length > 0 ? (
            <div style={{ marginBottom: 40, marginTop: 30 }}>
              {continueRide()}
            </div>
          ) : null}
        </div>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loader"></div>
          <p>Please wait...</p>
        </div>
      )}
      <Modal show={showErrorDialog} onHide={handleCloseErrorDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorDialog}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const PaymentMethodsInfo = ({
  rideInfo,
  paymentMethods,
  selectedPaymentMethodIndex,
  onSelectPaymentMethod,
  onAddNewMethod,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fare, rajloXFare, vehicle_category } = state;
  const amountToPay =
    vehicle_category === "Rajlo X"
      ? rajloXFare
      : fare === undefined
      ? state.destinationState.fare
      : fare;

  return (
    <div style={{ marginTop: "16px" }}>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <h2
          style={{
            marginBottom: "8px",
            flex: 0.6,
            fontWeight: "bold",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          Payment Method
        </h2>

        <div
          style={{
            flex: 0.4,
            textAlign: "right",
            color: "red",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          ${parseFloat(amountToPay).toFixed(2)}
        </div>
      </div>
      <div>
        {paymentMethods && paymentMethods.length > 0 ? (
          <PaymentMethodItem
            paymentMethods={paymentMethods}
            selectedPaymentMethodIndex={selectedPaymentMethodIndex}
            onSelectPaymentMethod={onSelectPaymentMethod}
            onAddNewMethod={onAddNewMethod}
          />
        ) : (
          <button onClick={onAddNewMethod} className="paymentbuttonStyle">
            <FiPlusCircle className="plusicon" />
            Add New Method
          </button>
        )}
      </div>
    </div>
  );
};

export default Payment;
